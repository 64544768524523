import React, { useState, useMemo } from "react";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { importManifest } from "../../../utils/api/accountSetting";
import { useSelector } from "react-redux";
import { TailSpinButtonLoader } from "../../../utils/TailSpinLoader";

const ManifestImportItems = () => {
  const [data, setData] = useState(null);
  const [apiResult, setApiResult] = useState(null);
  const accessToken = useSelector((state) => state.auth.token);
  const [headers, setHeaders] = useState([]);
  const [selectedHeaders, setSelectedHeaders] = useState({});
  const userSubscription = useSelector((state) => state.auth.userSubscription);
  const user = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const dropdownOptions = useMemo(
    () => ["asin", "lpn", "description", "gldesc", "msrp"],[]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setApiResult(null);
    if (file) {
      const fileType = file.type;

      if (fileType === "text/csv") {
        Papa.parse(file, {
          header: true,
          complete: (result) => {
            const filteredData = result.data.filter((row) => {
              // Check if any field in the row is empty
              return Object.values(row).every((value) => value.trim() !== "");
            });
        
            const updatedData = filteredData.map((row) => ({
              ...row,
              user: user.email,
              upc: user.name+'/'+user.email
          }));

            setData(updatedData);

            if (result.data.length > 0) {
              const csvHeaders = Object.keys(result.data[0]);
              setHeaders(csvHeaders);
            }
          },
          error: (error) => {
            console.error("Error parsing CSV file", error);
          },
        });
      }

      // Handle Excel file
      if (
        fileType ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        fileType === "application/vnd.ms-excel"
      ) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = XLSX.utils.sheet_to_json(
            workbook.Sheets[sheetName]
          );
          const csvHeaders = Object.keys(worksheet[0]);
          setHeaders(csvHeaders);
          setData(worksheet);
        };
        reader.readAsArrayBuffer(file);
      }
    }
  };

  // Handler to update selected value for each header
  const handleHeaderSelection = (oldHeader, newHeader) => {
    setSelectedHeaders((prevSelectedHeaders) => ({
      ...prevSelectedHeaders,
      [oldHeader]: newHeader,
    }));

    if (data && data.length > 0) {
      const updatedData = data.map((item) => {
        const newItem = {};
        Object.keys(item).forEach((key) => {
          if (key === oldHeader) {
            newItem[newHeader] = item[key];
          } else {
            newItem[key] = item[key];
          }
        });
        return newItem;
      });
      setData(updatedData);
    }
  };

  const manifestImporthandle = async () => {
    setIsLoading(true); // Start loading

    const res = await importManifest(data,accessToken);
    if (res.status === 200) {
      setApiResult(res.data);
    } else if (res.status === 500) {
      setError(res.data.error);
      setData([]);
      setHeaders([]);
    }
    setIsLoading(false);
  };

  const handleLabelClick = (event) => {
    if (
      userSubscription?.plan_type === undefined ||
      userSubscription?.plan_type === null ||
      userSubscription?.plan_type === "free" ||
      userSubscription?.plan_type === "basic"
    ) {
      event.preventDefault();
      setError("Please upgrade your plan to proceed further.");
    }
  };

  const downloadErrors = () => {
    const csvContent = "data:text/csv;charset=utf-8," 
        + apiResult.errors.map(e => e).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "errors.csv");
    document.body.appendChild(link); 

    link.click();
    document.body.removeChild(link); 
  };

  return (
    <div className="profile-sec">
      <div className="tp-label">
        <h4>Manifest Import</h4>
      </div>
      <div className="mb-2 col-sm-12">
        <label
          className="custom-btn btn-3"
          htmlFor="file-upload"
          onClick={handleLabelClick}
        >
          Import Manifest
          <input
            id="file-upload"
            type="file"
            style={{ display: "none" }}
            accept=".csv, .xls, .xlsx"
            onChange={handleFileChange}
          />
        </label>
        {error && <div className="error-message">{error}</div>}
      </div>

      {headers.length > 0 && (
        <div className="headers-csv-import mt-3">
          <h4 className="mb-2">Select Fields for Headers:</h4>
          {headers.map((header) => (
            <div
              key={header}
              className="header-dropdown d-flex justify-content-between mb-2"
            >
              <label>{header}</label>
              <select
                className="form-select"
                value={selectedHeaders[header] || ""}
                onChange={(e) => handleHeaderSelection(header, e.target.value)}
                style={{ width: "50%" }}
              >
                <option value="">Select</option>
                {dropdownOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          ))}
          <div className="profile-submit">
            <button className="custom-btn btn-3" onClick={manifestImporthandle}>
              <span  style={{ marginRight: true ? "5px" : "0" }}>Save</span>{"  "}
                {isLoading && <TailSpinButtonLoader/>}
            </button>
          </div>
        
        </div>
      )}

      {apiResult && (
        <div className="api-response">
          <div className="api-response1">
            <p>Success Count : {apiResult?.success_count}</p>
            <p>Error Count : {apiResult?.error_count}</p>
            <p className="mt-3">{apiResult.errors && apiResult.errors.length > 0 && (
                              <button className="custom-btn btn-3" onClick={downloadErrors}>Download Errors</button>
                          )}
            </p>
          </div>        
        </div>
      )}
    </div>
  );
};

export default ManifestImportItems;
